// BREAKPOINTS
export const breakpoints: Record<'xl' | 'lg' | 'md' | 'sm' | 'xs', number> = {
  xl: 1500,
  lg: 1279,
  md: 1025,
  sm: 768,
  xs: 414,
};

// ROUTES
export const accessRoute = '/access';
export const accessKey = 'pf_acs';
export const defaultPassword = 'Y@^p#6%3Ash8T2';
// BANNER
export const defaultFlipText = ['GOALS', 'HEALTH', 'DISCIPLINE', 'STRENGTH'];

// START - MAPBOX
export const MAP_API_KEY =
  process.env.GATSBY_MAPBOX_API_KEY ||
  'pk.eyJ1IjoicGx1c2ZpdG5lc3MiLCJhIjoiY2txNXVlbWo4MHR5azJwb2h1Z3N5bTdxMCJ9.RGW_SHvAZmYjqgzWbAma5Q';
export const baseMap = 'mapbox';
export const mapId = 'streets-v8';
export const basedMapStyle = `mapbox://styles/${baseMap}/${mapId}`;
export const defaultMapCenter: [number, number] = [135, -27]; // Australia
export const defaultMapZoom: [number] = [3];
export const gymLocationMapZoom: [number] = [2.5];
export const gymLocationMapZoomMobile: [number] = [2.25];

export const siteZoomLevels: any = {
  nz: {
    desktop: [4],
    mobile: [4],
  },
  au: {
    desktop: [2.5],
    mobile: [2.25],
  },
  in: {
    desktop: [4.5],
    mobile: [4.25],
  },
  id: {
    desktop: [4.5],
    mobile: [3.25],
  },
  other: {
    desktop: [2.5],
    mobile: [2.25],
  },
};
export const state = [
  'ACT',
  'QLD',
  'NSW',
  'VIC',
  'WA',
  'TAS',
  'SA',
  'NT',
  'NZ',
];
export const maxDateValidate = 6;
// END - MAPBOX

export const consentFormQuestions = [
  {
    title:
      'I confirm the information provided on this form to be true and correct <br/><span id="previewFile" class="font-bold text-xs cursor-pointer text-white">Membership Agreement.pdf</span>',
    answer: false,
  },
  {
    title: 'I agree to the Plus Fitness Terms and Conditions',
    answer: false,
  },
  {
    title:
      'I accept responsibility for the payment of my child’s membership \n (by direct debit or upfront payment) and any fees or fines that are \n incurred in connection with that membership, regardless of if I have \n been nominated as the payee of the account.',
    answer: false,
  },
  {
    title:
      'If I have been nominated as they payee of this membership,\n I confirm the billing information to be correct. \n a. I authorize recurring direct debits to be withdrawn from nominated details. \n b. I am aware I need to update ‘Plus Fitness’ of any changes to the \n nominated billing details.',
    answer: false,
  },
  {
    title:
      'I confirm the information provided on the Health questionnaire by \n “insert member name” to be true & correct.',
    answer: false,
  },
  {
    title:
      'I agree that my child’s membership may be cancelled due to a breach of the membership Terms and Conditions.',
    answer: false,
  },
  {
    title:
      'I give permission for my child to receive first aid, medical or ambulance assistance in the case of an accident or emergency and agree to pay any costs incurred.',
    answer: false,
  },
  {
    title:
      'I have been provided with a copy of the Privacy Policy and agree to be\n `bound by its terms.',
    answer: false,
  },
];
