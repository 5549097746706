import React, { useContext, useEffect, useRef } from 'react';

import { AuthContext } from '@/api/auth';

import ThreeDot from '../loading/ThreeDot';

function ModalBase(props) {
  const target = useRef<any>(null);
  const userInfo = useContext(AuthContext).userInfo;

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (target.current && !target.current?.contains(e.target)) {
        props.onClickOutSide ? props.onClickOutSide() : () => {};
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [target]);
  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[100] bg-backDrop">
      <div
        ref={target}
        className="bg-backDrop fixed sm:w-[375px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-black text-white z-50 border sm:border-black border-transparent"
      >
        {props?.loading && <ThreeDot />}
        {!props.noBaseHeader && (
          <div className="flex items-center justify-between pt-[33px] md:pt-6 pr-[33px] pl-[33px] sm:px-6 ">
            <div className="flex items-center gap-[17px] ">
              <img
                src={userInfo?.imageUrl}
                className="inline-block rounded-full h-[38.7px] w-[38.7px] "
              />
              <span className="uppercase gotham text-[20px] font-bicyclette tracking-[0.01em]">
                hello {userInfo?.givenName} {userInfo?.surname}
              </span>
            </div>
            <div
              onClick={() => props.closeModal(false)}
              className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
            >
              <div className="w-4 h-4 block">
                <img
                  src="/icons/close.png"
                  alt="close"
                  className="object-fit w-full"
                />
              </div>
            </div>
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
}
// function useOutsideAlerter(ref) {
//   useEffect(() => {
//     /**
//      * Alert if clicked on outside of element
//      */
//     function handleClickOutside(event) {
//       if (ref.current && !ref.current.contains(event.target)) {
//         alert("You clicked outside of me!");
//       }
//     }
//     // Bind the event listener
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       // Unbind the event listener on clean up
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [ref]);
// }

// /**
//  * Component that alerts if you click outside of it
//  */
// export default function OutsideAlerter(props) {
//   const wrapperRef = useRef(null);
//   useOutsideAlerter(wrapperRef);

//   return <div ref={wrapperRef}>{props.children}</div>;
// }
export default ModalBase;
