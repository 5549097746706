import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import { useMemberShipContext } from '@/context/MembershipContext';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const MembershipPlan = ({
  userInfo,
  currentVoucher,
  isEndPaymentDelayGreaterThanToday,
  nearestDate,
  minDD,
  setMinDD,
  membershipStatus,
  isLocationOpening,
  setTab,
  tabs,
  setChangeSuspensionDate,
  changeSuspensionDate,
  setRevokeSuspendModal,
  setRevokeModal,
}) => {
  const { userSelectedMembership } = useMemberShipContext();
  const [nextDD, setNextDD] = useState();
  useEffect(() => {
    const dataFilter =
      userSelectedMembership?.billings?.items.length > 0 &&
      nearestDate(
        userSelectedMembership?.billings?.items?.filter((i) => !i.isProcessed)
      );

    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    if (userSelectedMembership?.recurring)
      setMinDD(new Date(dataFilter?.debitDate || nextDate));
    else setMinDD(nextDate);
    setNextDD(dataFilter);
  }, [userSelectedMembership]);
  const calcNextDD = () => {
    return userSelectedMembership?.paymentFrequency?.type.toLowerCase() ===
      'weeks' ? (
      <p className=" text-white gotham text-sm">
        {moment(new Date(nextDD?.debitDate)).format('DD/MM/yyyy')}
      </p>
    ) : (
      <p className="mt-[3px] text-white gotham text-sm"> No direct debit</p>
    );
  };
  return (
    <div>
      {/* // DESKTOP UI */}
      <div className="block sm:hidden">
        <div className="border-b font-bicyclette border-white font-medium text-white px-6 tracking-[1.4px] py-[19.5px] uppercase">
          membership plan
        </div>
        <div className="pt-[18px] pb-[14px] mx-6 text-white border-b border-gray flex">
          <div className=" w-[40%] flex flex-col gap-[3px]">
            <div className="text-[#898c8d] text-[11px] font-normal leading-[17px]">
              Home club
            </div>
            <div>
              <div className="text-white gotham text-sm leading-[20px]">
                {uppercaseFirstLetter(userInfo?.homeLocationDetails.name)}
              </div>
            </div>
          </div>
          {currentVoucher?.type !== 'VISIT_PASS' &&
            currentVoucher?.type !== 'COMPLIMENTARY' && (
              <div className="w-[60%] flex flex-col gap-[3px]">
                <div className="text-[#898c8d] text-[11px] font-normal leading-[17px]">
                  Membership amount
                </div>
                <div>
                  <p className="text-white gotham text-sm leading-[20px]">
                    $
                    {Object.keys(currentVoucher || {}).length &&
                    currentVoucher.membershipDetail?.id ===
                      userSelectedMembership?.membershipId
                      ? currentVoucher.type === 'PERCENT'
                        ? (
                            currentVoucher?.membershipDetail?.costPrice -
                            currentVoucher?.membershipDetail?.costPrice *
                              (currentVoucher?.cost * 0.01)
                          ).toFixed(2)
                        : currentVoucher.cost?.toFixed(2)
                      : userSelectedMembership?.costPrice.toFixed(2)}
                  </p>
                </div>
              </div>
            )}
        </div>

        <div className="pt-[18px] pb-[14px] mx-6 text-white border-b border-gray flex justify-between items-center mb-[34px]">
          <div className="w-[40%] flex flex-col gap-[3px]">
            <div className="text-[#898c8d] text-[11px] leading-[17px]">
              Membership Name
            </div>
            <div>
              <h2 className=" text-white gotham text-sm">
                {userSelectedMembership?.membershipName}
              </h2>
            </div>
          </div>
          <div className="w-[60%] flex justify-between items-center gap-2">
            <div>
              <div>
                <h2 className=" text-white gotham text-sm">
                  {moment().diff(
                    userSelectedMembership?.startDateTime,
                    'days'
                  ) < 7 && 'You are in a 7-day cooling-off period.'}
                </h2>
              </div>
            </div>{' '}
            {!isEndPaymentDelayGreaterThanToday && (
              <div className="">
                {membershipStatus.status === 'active' &&
                  !userInfo?.isBlocked &&
                  userSelectedMembership.recurring && (
                    <PrimaryOutlinedButton
                      disabled={
                        currentVoucher?.type === 'VISIT_PASS' ||
                        currentVoucher?.type === 'COMPLIMENTARY' ||
                        userInfo?.outstandingBalance ||
                        !isLocationOpening
                      }
                      className={`rounded-md w-[180px]  h-[37px] !text-[11px] ${
                        currentVoucher?.type === 'VISIT_PASS' ||
                        currentVoucher?.type === 'COMPLIMENTARY' ||
                        userInfo?.outstandingBalance ||
                        !isLocationOpening
                          ? '!bg-[#414141] border-none !text-[#848484]'
                          : ''
                      }`}
                      onClick={() => setTab(1)}
                    >
                      CHANGE MEMBERSHIP
                    </PrimaryOutlinedButton>
                  )}
              </div>
            )}
          </div>
        </div>
        {currentVoucher?.type !== 'VISIT_PASS' &&
          currentVoucher?.type !== 'COMPLIMENTARY' && (
            <div className="pt-[18px] pb-[13px] mx-6 text-white border-b border-gray flex">
              <div className=" w-[40%] flex flex-col gap-[3px] justify-center">
                <div className="text-white text-sm">Next direct debit</div>
              </div>
              <div className="w-[60%] flex flex-col gap-[3px]">
                <div className="text-white text-sm ">{calcNextDD()}</div>
              </div>
            </div>
          )}

        {/* <div className='pt-[18px] pb-[14px] mx-6 text-white border-b border-gray flex'>
                <div className=' w-[40%] flex flex-col gap-[3px]'>
                    <div className='text-white'>contract auto-renews</div>
                </div>
                <div className='w-[60%] flex flex-col gap-[3px]'>
                    <div className='text-white'>N/A</div>
                </div>
            </div> */}
        <div
          className={`py-[35px] flex gap-[18px] mx-6 ${
            membershipStatus.status !== 'pendingCancellation' &&
            membershipStatus.status !== 'pendingSuspension' &&
            membershipStatus.status !== 'future' &&
            membershipStatus.status !== 'suspended'
              ? 'justify-end'
              : 'justify-between'
          }`}
        >
          {membershipStatus.status === 'future' && (
            <>
              <div className="">
                <p className="uppercase text-[#fbff4b] text-sm font-bold leading-[160%]">
                  you have one future membership which{' '}
                  {userSelectedMembership.isPending ? (
                    <>
                      is
                      <br /> awaiting approval from your parents and staff
                    </>
                  ) : (
                    <>
                      will
                      <br /> start on{' '}
                      {moment(
                        utcToZonedTime(
                          userSelectedMembership.startDateTime,
                          userSelectedMembership.timezone || 'Australia/sydney'
                        )
                      ).format('Do MMMM YYYY')}
                    </>
                  )}
                </p>
              </div>
            </>
          )}
          {membershipStatus.status === 'pendingCancellation' && (
            <div className="">
              <p className="uppercase text-textError text-sm font-bold leading-[160%]">
                request to cancel your membership is pending
              </p>
              <div className="flex items-center">
                {/* <p className="text-textError text-sm leading-[140%] mr-6">
                  <b>Request date:</b> 6th July 2023
                </p> */}
                <p className="text-textError text-sm leading-[140%] mr-[10px]">
                  <b>Cancellation date:</b>{' '}
                  {moment(membershipStatus?.cancellationDate).format(
                    'Do MMMM YYYY'
                  )}
                </p>
                <span className="text-textSecondary text-xs leading-[140%]">
                  ({membershipStatus.remainingDay} days remaining)
                </span>
              </div>
            </div>
          )}

          {membershipStatus.status == 'pendingSuspension' && (
            <div className="">
              <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                Your Membership Suspension is{' '}
                {membershipStatus.suspensionStatus === 'PENDING'
                  ? 'AWAITING APPROVE'
                  : membershipStatus.suspensionStatus === 'APPROVED'
                  ? 'PENDING'
                  : ''}
              </p>
              <div className="flex items-center">
                <p className="text-[#FF6900] text-sm leading-[140%] mr-6">
                  Suspension period will start on{' '}
                  {moment(
                    utcToZonedTime(
                      membershipStatus.suspendStartDate,
                      userSelectedMembership?.timezone ?? 'Australia/sydney'
                    )
                  ).format('Do MMMM YYYY')}{' '}
                  ends on{' '}
                  {moment(
                    utcToZonedTime(
                      membershipStatus.suspendEndDate,
                      userSelectedMembership?.timezone ?? 'Australia/sydney'
                    )
                  ).format('Do MMMM YYYY')}
                </p>
              </div>
            </div>
          )}

          {membershipStatus.status === 'suspended' && (
            <div className="">
              <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                your membership has been suspended
              </p>
              <div className="flex items-center">
                <p className="text-[#FF6900] text-sm leading-[140%] mr-6">
                  Suspension period ends on{' '}
                  {moment(membershipStatus.suspendEndDate).format(
                    'Do MMMM YYYY'
                  )}
                </p>
              </div>
            </div>
          )}
          {isLocationOpening ? (
            <div className="flex items-center gap-[18px]">
              {/* SUSPENSION --- PENDING CASE */}

              {(membershipStatus.status === 'suspended' ||
                membershipStatus.status === 'pendingSuspension') && (
                <>
                  <PrimaryOutlinedButton
                    className="rounded-md"
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: true,
                      });
                      setRevokeSuspendModal(true);
                    }}
                  >
                    CHANGE SUSPENSION DATE
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    className="rounded-md"
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: false,
                      });
                      setRevokeSuspendModal(true);
                    }}
                  >
                    CANCEL SUSPENSION
                  </PrimaryOutlinedButton>
                </>
              )}

              {/* CANCEL --- PENDING CASE */}
              {membershipStatus.status === 'pendingCancellation' && (
                <PrimaryOutlinedButton
                  onClick={() => {
                    setRevokeModal(true);
                  }}
                  className="rounded-md"
                >
                  Stop Cancellation
                </PrimaryOutlinedButton>
              )}

              {membershipStatus.status !== 'pendingSuspension' &&
                membershipStatus.status !== 'pendingCancellation' &&
                membershipStatus.status !== 'suspended' && (
                  <>
                    {!isEndPaymentDelayGreaterThanToday && (
                      <div>
                        {!userInfo?.isBlocked &&
                          membershipStatus.status !== 'future' && (
                            <PrimaryOutlinedButton
                              disabled={
                                currentVoucher?.type === 'VISIT_PASS' ||
                                currentVoucher?.type === 'COMPLIMENTARY' ||
                                userInfo?.outstandingBalance
                              }
                              className={`rounded-md w-[185px] h-[37px] !text-[11px] !px-0 ${
                                currentVoucher?.type === 'VISIT_PASS' ||
                                currentVoucher?.type === 'COMPLIMENTARY' ||
                                userInfo?.outstandingBalance
                                  ? '!bg-[#414141] border-none !text-[#848484]'
                                  : ''
                              }`}
                              onClick={() => setTab(2)}
                            >
                              SUSPEND MEMBERSHIP
                            </PrimaryOutlinedButton>
                          )}
                      </div>
                    )}
                    {!isEndPaymentDelayGreaterThanToday && (
                      <div>
                        {userSelectedMembership?.recurring && (
                          <PrimaryOutlinedButton
                            disabled={
                              currentVoucher?.type === 'VISIT_PASS' ||
                              currentVoucher?.type === 'COMPLIMENTARY' ||
                              userInfo?.outstandingBalance
                            }
                            className={`rounded-md max-w-[200px] h-[37px] !text-[11px] ${
                              currentVoucher?.type === 'VISIT_PASS' ||
                              currentVoucher?.type === 'COMPLIMENTARY' ||
                              userInfo?.outstandingBalance
                                ? '!bg-[#414141] border-none !text-[#848484]'
                                : ''
                            }`}
                            onClick={() => setTab(3)}
                          >
                            {userSelectedMembership?.expiryDateTime
                              ? 'REVOKE CANCELATION'
                              : 'REQUEST CANCELATION'}
                          </PrimaryOutlinedButton>
                        )}
                      </div>
                    )}
                  </>
                )}
            </div>
          ) : null}
        </div>
      </div>
      {/* // MOBILE UI */}
      <div className="hidden sm:block px-6 pb-20">
        <div className="pb-[14px] ">
          <p className="text-textSecondary text-base leading-[21.36px] mt-[2px] mb-2 font-normal">
            Home Club
          </p>

          <h2 className="text-white text-[25px] font-bold font-bicyclette leading-[22.5px] mb-2">
            {uppercaseFirstLetter(userInfo?.homeLocationDetails.name)}
          </h2>

          <div className="flex flex-col mb-[14px]">
            <p className="text-white font-base leading-[22.5px] font-normal">
              {userInfo?.homeLocationDetails.address?.address}{' '}
            </p>
            {/*
                Shop 10 South.Poiunt

              <p className="text-white font-base leading-[21.36px]">
                Athlon Drive, Greenway ACT 2900
              </p> */}
            <a
              href={'mailto: ' + userInfo?.homeLocationDetails.email}
              className="text-white text-[15px] font-base leading-[22.5px] font-normal"
            >
              E:{' '}
              <span className="lowercase font-normal">
                {userInfo?.homeLocationDetails.email}
              </span>
            </a>
            <a
              href={`tel:${userInfo?.homeLocationDetails.phoneNumber}`}
              className="text-white text-[15px] font-base leading-[22.5px] font-normal"
            >
              T: {userInfo?.homeLocationDetails.phoneNumber}
            </a>
          </div>

          <div className="border-t-[0.5px] border-textSecondary pt-3 pb-[14px]">
            <p className="text-textSecondary mb-[5px] font-normal leading-[23px]  ">
              Membership Name
            </p>
            <p
              className={` font-bold leading-[23px] ${
                membershipStatus.status !== 'active'
                  ? 'text-textSecondary'
                  : 'text-white'
              }`}
            >
              {userSelectedMembership?.membershipName?.split(' Membership')[0]}{' '}
              - ${userSelectedMembership?.costPrice.toFixed(2)}
              {userSelectedMembership?.paymentFrequency.type === 'WEEKS' &&
                ' per fortnight'}
            </p>
            {moment().diff(userSelectedMembership?.startDateTime, 'days') <
              7 && (
              <p className="text-white my-1">
                You are in a 7-day cooling-off period. To cancel, please contact
                your local Plus Fitness.
              </p>
            )}
            {/* SUSPENSION --- PENDING CASE */}
            {membershipStatus.status == 'pendingSuspension' && (
              <div className="mt-2">
                <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                  Your Membership Suspension is Pending
                </p>
                <div className="flex items-center mt-1">
                  <p className="text-[#FF6900] text-base leading-[133.5%]">
                    Suspension period start on{' '}
                    {moment(
                      utcToZonedTime(
                        membershipStatus.suspendStartDate,
                        userSelectedMembership.timezone ?? 'Australia/sydney'
                      )
                    ).format('Do MMMM YYYY')}
                  </p>
                </div>
              </div>
            )}
            {membershipStatus.status === 'future' && (
              <>
                <div className="mt-2">
                  <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                    you have one future membership which{' '}
                    {userSelectedMembership.isPending ? (
                      <>
                        is
                        <br /> awaiting approval from your parents and staff
                      </>
                    ) : (
                      <>
                        will
                        <br />
                        start on{' '}
                        {moment(
                          utcToZonedTime(
                            userSelectedMembership.startDateTime,
                            userSelectedMembership?.timezone ??
                              'Australia/sydney'
                          )
                        ).format('Do MMMM YYYY')}
                      </>
                    )}
                  </p>
                </div>
              </>
            )}
            {membershipStatus.status === 'suspended' && (
              <div className="">
                <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                  your membership has been suspended
                </p>
                <div className="flex items-center">
                  <p className="text-[#FF6900] text-sm leading-[140%] mr-6">
                    Suspension period ends on{' '}
                    {moment(membershipStatus.suspendEndDate).format(
                      'Do MMMM YYYY'
                    )}
                  </p>
                </div>
              </div>
            )}
            {/* CANCEL --- PENDING CASE */}
            {membershipStatus.status === 'pendingCancellation' && (
              <div className="mt-2">
                <p className="uppercase text-textError text-base font-bold leading-[160%]">
                  request to cancel your membership is pending
                </p>
                <div className="flex items-center mt-1 sm:flex-col sm:items-start">
                  <p className="text-textError text-base leading-[133.5%]">
                    <b>Cancellation date:</b>{' '}
                    {moment(membershipStatus?.cancellationDate).format(
                      'Do MMMM YYYY'
                    )}
                  </p>
                  <span className="text-textSecondary text-xs leading-[133.5%] mt-1">
                    ({membershipStatus.remainingDay} days remaining)
                  </span>
                </div>
              </div>
            )}
          </div>

          <div className="border-t-[0.5px] border-textSecondary pb-[14px] pt-3">
            <p className="text-textSecondary mb-[5px] font-normal leading-[23px]">
              Next Direct Debit
            </p>
            <p className="text-white font-bold leading-[23px]">
              {[userSelectedMembership]?.map((item: any, index: number) => {
                const dataFilter =
                  item?.billings?.items.length > 0 &&
                  nearestDate(
                    item?.billings?.items?.filter((i) => !i.isProcessed)
                  );

                if (!minDD) {
                  const currentDate = new Date();
                  const nextDate = new Date(currentDate);
                  nextDate.setDate(currentDate.getDate() + 1);
                  if (item?.recurring)
                    setMinDD(new Date(dataFilter?.debitDate || nextDate));
                  else setMinDD(nextDate);
                }
                return item?.paymentFrequency?.type.toLowerCase() ===
                  'weeks' ? (
                  <p className=" text-white gotham " key={index}>
                    {moment(new Date(dataFilter?.debitDate)).format(
                      'DD/MM/yyyy'
                    )}
                  </p>
                ) : (
                  <p className="mt-[3px] text-white gotham " key={index}>
                    {' '}
                    No direct debit
                  </p>
                );
              })}
            </p>
          </div>

          {/* <div className="border-t-[0.5px] border-textSecondary py-3">
              <p className="text-textSecondary mb-3">Contract Auto-Renewal</p>
              <p className="text-white font-bold">N/A</p>
            </div> */}
          {isLocationOpening ? (
            <div className="buttonGroup flex flex-col gap-y-5 mt-4">
              {membershipStatus.status !== 'pendingSuspension' &&
                membershipStatus.status !== 'suspended' &&
                membershipStatus.status !== 'pendingCancellation' && (
                  <>
                    {tabs
                      .filter((item, index) => index !== 0)
                      .map((i, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            membershipStatus.status == 'active' &&
                              (!userInfo?.isBlocked ||
                                index === 0 ||
                                index === 3) &&
                              ((currentVoucher?.type !== 'VISIT_PASS' &&
                                currentVoucher?.type !== 'COMPLIMENTARY') ||
                                index === 3) &&
                              isLocationOpening &&
                              setTab(index + 1);
                          }}
                        >
                          <PrimaryOutlinedButton
                            className={` h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase text-center tracking-[1.4px] rounded-t-md h-[32px] uppercase flex items-center justify-center text-sm gotham ${
                              membershipStatus.status == 'active' &&
                              (!userInfo?.isBlocked ||
                                index === 0 ||
                                index === 3) &&
                              isLocationOpening
                                ? 'cursor-pointer  font-medium text-white'
                                : 'cursor-default   !text-[#848484]'
                            } ${
                              (currentVoucher?.type !== 'VISIT_PASS' &&
                                currentVoucher?.type !== 'COMPLIMENTARY') ||
                              index === 3
                                ? 'cursor-pointer  font-medium text-white'
                                : '!cursor-default hover:bg-transparent  hover:border-white  !text-[#848484]'
                            }`}
                          >
                            {index === 0
                              ? userSelectedMembership?.recurring
                                ? 'CHANGE MEMBERSHIP'
                                : 'SUSPEND MEMBERSHIP'
                              : ''}
                            {index === 1 && 'SUSPEND MEMBERSHIP'}
                            {index === 2 && 'REQUEST CANCELLATION'}
                            {index === 3 && 'VOUCHERS'}
                          </PrimaryOutlinedButton>
                        </div>
                      ))}
                  </>
                )}

              {/* CANCEL --- PENDING CASE */}
              {membershipStatus.status === 'pendingCancellation' && (
                <PrimaryOutlinedButton
                  onClick={() => {
                    setRevokeModal(true);
                  }}
                  className="rounded-md sm:text-base sm:font-bold sm:uppercase sm:leading-[160%] sm:h-[52px]"
                >
                  REVOKE CANCELLATION
                </PrimaryOutlinedButton>
              )}

              {/* SUSPENSION --- PENDING CASE */}
              {(membershipStatus.status === 'pendingSuspension' ||
                membershipStatus.status === 'suspended') && (
                <>
                  <PrimaryOutlinedButton
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: false,
                      });
                      setRevokeSuspendModal(true);
                    }}
                    className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    cancel suspension
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: true,
                      });
                      setRevokeSuspendModal(true);
                    }}
                    className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    change suspension
                  </PrimaryOutlinedButton>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default MembershipPlan;
